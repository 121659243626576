export class SearchFakeData {
  public static search = [
    {
      groupTitle: 'Contacts',
      searchLimit: 4,
      data: []
    }
    // {
    //   groupTitle: 'Pages',
    //   searchLimit: 4,
    //   bookmarkLimit: 6,
    //   data: [
    //     {
    //       id: 0,
    //       target: 'analyticsDash',
    //       isBookmarked: false,
    //       title: 'Analytics Dashboard',
    //       icon: 'home',
    //       link: '/dashboard/analytics'
    //     },
    //     {
    //       id: 1,
    //       target: 'pacientsDash',
    //       isBookmarked: false,
    //       title: 'Pacients Dashboard',
    //       icon: 'home',
    //       link: '/'
    //     },
    //     {
    //       id: 2,
    //       target: 'email',
    //       isBookmarked: true,
    //       title: 'Email',
    //       icon: 'mail',
    //       link: '/apps/email'
    //     },
    //     {
    //       id: 3,
    //       target: 'chat',
    //       isBookmarked: true,
    //       title: 'Chat',
    //       icon: 'message-square',
    //       link: '/apps/chat'
    //     },
    //     {
    //       id: 4,
    //       target: 'todo',
    //       isBookmarked: true,
    //       title: 'Todo',
    //       icon: 'check-square',
    //       link: '/apps/todo'
    //     },
    //     {
    //       id: 5,
    //       target: 'calendar',
    //       isBookmarked: true,
    //       title: 'Calendar',
    //       icon: 'calendar',
    //       link: '/apps/calendar'
    //     },
    //     {
    //       id: 6,
    //       target: 'invoiceList',
    //       isBookmarked: false,
    //       title: 'Invoice List',
    //       icon: 'list',
    //       link: '/apps/invoice/list'
    //     },
    //     {
    //       id: 7,
    //       target: 'invoicePreview',
    //       isBookmarked: false,
    //       title: 'Invoice Preview',
    //       icon: 'file-text',
    //       link: '/apps/invoice/preview/4987'
    //     },
    //     {
    //       id: 8,
    //       target: 'invoiceEdit',
    //       isBookmarked: false,
    //       title: 'Invoice Edit',
    //       icon: 'edit',
    //       link: '/apps/invoice/edit/4987'
    //     },
    //     {
    //       id: 9,
    //       target: 'invoiceAdd',
    //       isBookmarked: false,
    //       title: 'Invoice Add',
    //       icon: 'plus',
    //       link: '/apps/invoice/add'
    //     },
    //     {
    //       id: 10,
    //       target: 'shop',
    //       isBookmarked: false,
    //       title: 'Shop Ecommerce',
    //       icon: 'shopping-cart',
    //       link: '/apps/e-commerce/shop'
    //     },
    //     {
    //       id: 11,
    //       target: 'detail',
    //       isBookmarked: false,
    //       title: 'Product Detail',
    //       icon: 'circle',
    //       link: '/apps/e-commerce/details/27'
    //     },
    //     {
    //       id: 12,
    //       target: 'wishList',
    //       isBookmarked: false,
    //       title: 'Wish List',
    //       icon: 'heart',
    //       link: '/apps/e-commerce/wishlist'
    //     },
    //     {
    //       id: 13,
    //       target: 'checkout',
    //       isBookmarked: false,
    //       title: 'Checkout',
    //       icon: 'credit-card',
    //       link: '/apps/e-commerce/checkout'
    //     },
    //     {
    //       id: 14,
    //       target: 'userList',
    //       isBookmarked: false,
    //       title: 'User List',
    //       icon: 'user',
    //       link: '/apps/user/user-list'
    //     },
    //     {
    //       id: 15,
    //       target: 'userView',
    //       isBookmarked: false,
    //       title: 'User View',
    //       icon: 'user',
    //       link: '/apps/user/user-view/2'
    //     },
    //     {
    //       id: 16,
    //       target: 'userEdit',
    //       isBookmarked: false,
    //       title: 'User Edit',
    //       icon: 'user',
    //       link: '/apps/user/user-edit/2'
    //     },
    //     {
    //       id: 17,
    //       target: 'login-v1',
    //       isBookmarked: false,
    //       title: 'Login v1 Page',
    //       icon: 'user-plus',
    //       link: '/pages/authentication/login-v1'
    //     },
    //     {
    //       id: 18,
    //       target: 'login-v2',
    //       isBookmarked: false,
    //       title: 'Login v2 Page',
    //       icon: 'user-plus',
    //       link: '/pages/authentication/login-v2'
    //     },
    //     {
    //       id: 19,
    //       target: 'register-v1',
    //       isBookmarked: false,
    //       title: 'Register v1 Page',
    //       icon: 'user-plus',
    //       link: '/pages/authentication/register-v1'
    //     },
    //     {
    //       id: 20,
    //       target: 'register-v2',
    //       isBookmarked: false,
    //       title: 'Register v2 Page',
    //       icon: 'user-plus',
    //       link: '/pages/authentication/register-v2'
    //     },
    //     {
    //       id: 21,
    //       target: 'forgotPassword-v1',
    //       isBookmarked: false,
    //       title: 'Forgot Password v1 Page',
    //       icon: 'trending-up',
    //       link: '/pages/authentication/forgot-password-v1'
    //     },
    //     {
    //       id: 22,
    //       target: 'forgotPassword-v2',
    //       isBookmarked: false,
    //       title: 'Forgot Password v2 Page',
    //       icon: 'trending-up',
    //       link: '/pages/authentication/forgot-password-v2'
    //     },
    //     {
    //       id: 23,
    //       target: 'resetPassword-v1',
    //       isBookmarked: false,
    //       title: 'Reset Password v1 Page',
    //       icon: 'trending-up',
    //       link: '/pages/authentication/reset-password-v1'
    //     },
    //     {
    //       id: 24,
    //       target: 'lock-screen',
    //       isBookmarked: false,
    //       title: 'Lock Screen',
    //       icon: 'trending-up',
    //       link: '/pages/authentication/lock-screen'
    //     },
    //     {
    //       id: 25,
    //       target: 'accountSettings',
    //       isBookmarked: false,
    //       title: 'Account Settings',
    //       icon: 'settings',
    //       link: '/pages/account-settings'
    //     },
    //     {
    //       id: 26,
    //       target: 'profile',
    //       isBookmarked: false,
    //       title: 'Profile Page',
    //       icon: 'users',
    //       link: '/pages/profile',
    //       collapsed: true
    //     },
    //     {
    //       id: 27,
    //       target: 'faq',
    //       isBookmarked: false,
    //       title: 'FAQ Page',
    //       icon: 'zap',
    //       link: '/pages/faq'
    //     },
    //     {
    //       id: 28,
    //       target: 'knowledgeBase',
    //       isBookmarked: false,
    //       title: 'Knowledge Base Page',
    //       icon: 'align-left',
    //       link: '/pages/knowledge-base'
    //     },

    //     {
    //       id: 29,
    //       target: 'pricing',
    //       isBookmarked: false,
    //       title: 'Pricing',
    //       icon: 'dollar-sign',
    //       link: '/pages/pricing'
    //     },

    //     {
    //       id: 30,
    //       target: 'blogList',
    //       isBookmarked: false,
    //       title: 'Blog List',
    //       icon: 'list',
    //       link: '/pages/blog-list'
    //     },
    //     {
    //       id: 31,
    //       target: 'blogDetail',
    //       isBookmarked: false,
    //       title: 'Blog Details',
    //       icon: 'file-text',
    //       link: '/pages/blog-details/1'
    //     },
    //     {
    //       id: 32,
    //       target: 'blogEdit',
    //       isBookmarked: false,
    //       title: 'Blog Edit',
    //       icon: 'edit',
    //       link: '/pages/blog-edit'
    //     },

    //     {
    //       id: 33,
    //       target: 'comingSoon',
    //       isBookmarked: false,
    //       title: 'Coming Soon Page',
    //       icon: 'watch',
    //       link: '/miscellaneous/coming-soon'
    //     },

    //     {
    //       id: 34,
    //       target: 'notAuthorized',
    //       isBookmarked: false,
    //       title: 'Not Authorized Page',
    //       icon: 'user-x',
    //       link: '/miscellaneous/not-authorized'
    //     },
    //     {
    //       id: 35,
    //       target: 'maintenance',
    //       isBookmarked: false,
    //       title: 'Maintenance Page',
    //       icon: 'aperture',
    //       link: '/miscellaneous/maintenance'
    //     },
    //     {
    //       id: 36,
    //       target: 'error',
    //       isBookmarked: false,
    //       title: 'Error',
    //       icon: 'alert-triangle',
    //       link: '/miscellaneous/error'
    //     },
    //     {
    //       id: 37,
    //       target: 'typography',
    //       isBookmarked: false,
    //       title: 'Typography',
    //       icon: 'type',
    //       link: 'ui/content/typography'
    //     },
    //     {
    //       id: 38,
    //       target: 'colors',
    //       isBookmarked: false,
    //       title: 'Colors',
    //       icon: 'feather',
    //       link: '/ui/colors'
    //     },
    //     {
    //       id: 39,
    //       target: 'feather',
    //       isBookmarked: false,
    //       title: 'Feather Icons',
    //       icon: 'feather',
    //       link: '/ui/icons/feather'
    //     },
    //     {
    //       id: 40,
    //       target: 'basic',
    //       isBookmarked: false,
    //       title: 'Card Basic',
    //       icon: 'square',
    //       link: '/ui/card/card-basic'
    //     },
    //     {
    //       id: 41,
    //       target: 'cardAdvance',
    //       isBookmarked: false,
    //       title: 'Card Advance',
    //       icon: 'tablet',
    //       link: '/ui/card/advance'
    //     },
    //     {
    //       id: 42,
    //       target: 'cardStatistics',
    //       isBookmarked: false,
    //       title: 'Card Statistics',
    //       icon: 'tablet',
    //       link: '/ui/card/statistics'
    //     },
    //     {
    //       id: 43,
    //       target: 'CardAnalytics',
    //       isBookmarked: false,
    //       title: 'Card Analytics',
    //       icon: 'bar-chart-2',
    //       link: '/ui/card/analytics'
    //     },
    //     {
    //       id: 44,
    //       target: 'cardActions',
    //       isBookmarked: false,
    //       title: 'Card Actions',
    //       icon: 'air-play',
    //       link: '/ui/card/actions'
    //     },
    //     {
    //       id: 45,
    //       target: 'alerts',
    //       isBookmarked: false,
    //       title: 'Alerts',
    //       icon: 'info',
    //       link: '/components/alerts'
    //     },
    //     {
    //       id: 46,
    //       target: 'ratings',
    //       isBookmarked: false,
    //       title: 'Ratings',
    //       icon: 'star',
    //       link: '/components/ratings'
    //     },
    //     {
    //       id: 47,
    //       target: 'avatar',
    //       isBookmarked: false,
    //       title: 'Avatar',
    //       icon: 'user',
    //       link: '/components/avatar'
    //     },
    //     {
    //       id: 48,
    //       target: 'badges',
    //       isBookmarked: false,
    //       title: 'Badges',
    //       icon: 'circle',
    //       link: '/components/badges'
    //     },
    //     {
    //       id: 49,
    //       target: 'breadCrumbs',
    //       isBookmarked: false,
    //       title: 'Breadcrumbs',
    //       icon: 'more-horizontal',
    //       link: '/components/breadcrumbs'
    //     },
    //     {
    //       id: 50,
    //       target: 'buttons',
    //       isBookmarked: false,
    //       title: 'Buttons',
    //       icon: 'inbox',
    //       link: '/components/buttons'
    //     },
    //     {
    //       id: 51,
    //       target: 'carousel',
    //       isBookmarked: false,
    //       title: 'Carousel',
    //       icon: 'map',
    //       link: '/components/carousel'
    //     },
    //     {
    //       id: 52,
    //       target: 'collapse',
    //       isBookmarked: false,
    //       title: 'Collapse',
    //       icon: 'minimize',
    //       link: '/components/collapse'
    //     },
    //     {
    //       id: 53,
    //       target: 'divider',
    //       isBookmarked: false,
    //       title: 'Divider',
    //       icon: 'minus',
    //       link: '/components/divider'
    //     },
    //     {
    //       id: 54,
    //       target: 'dropDowns',
    //       isBookmarked: false,
    //       title: 'Dropdowns',
    //       icon: 'inbox',
    //       link: '/components/dropdowns'
    //     },
    //     {
    //       id: 55,
    //       target: 'listGroup',
    //       isBookmarked: false,
    //       title: 'List Group',
    //       icon: 'layers',
    //       link: '/components/list-group'
    //     },
    //     {
    //       id: 56,
    //       target: 'mediaObjects',
    //       isBookmarked: false,
    //       title: 'Media Objects',
    //       icon: 'image',
    //       link: '/components/media-objects'
    //     },
    //     {
    //       id: 57,
    //       target: 'modals',
    //       isBookmarked: false,
    //       title: 'Modals',
    //       icon: 'maximize-2',
    //       link: '/components/modals'
    //     },
    //     {
    //       id: 58,
    //       target: 'navsComponent',
    //       isBookmarked: false,
    //       title: 'Navs',
    //       icon: 'more-vertical',
    //       link: '/components/navs'
    //     },
    //     {
    //       id: 59,
    //       target: 'pagination',
    //       isBookmarked: false,
    //       title: 'Pagination',
    //       icon: 'chevrons-right',
    //       link: '/components/pagination'
    //     },
    //     {
    //       id: 60,
    //       target: 'pillBadges',
    //       isBookmarked: false,
    //       title: 'Pill Badges',
    //       icon: 'circle',
    //       link: '/components/pill-badges'
    //     },
    //     {
    //       id: 61,
    //       target: 'pillsComponent',
    //       isBookmarked: false,
    //       title: 'Pills Component',
    //       icon: 'toggle-right',
    //       link: '/components/pills'
    //     },
    //     {
    //       id: 62,
    //       target: 'popovers',
    //       isBookmarked: false,
    //       title: 'Popovers',
    //       icon: 'message-circle',
    //       link: '/components/popovers'
    //     },
    //     {
    //       id: 63,
    //       target: 'progress',
    //       isBookmarked: false,
    //       title: 'Progress',
    //       icon: 'server',
    //       link: '/components/progress'
    //     },
    //     {
    //       id: 64,
    //       target: 'spinner',
    //       isBookmarked: false,
    //       title: 'Spinner',
    //       icon: 'sun',
    //       link: '/components/spinner'
    //     },
    //     {
    //       id: 65,
    //       target: 'tabsComponent',
    //       isBookmarked: false,
    //       title: 'Tabs',
    //       icon: 'server',
    //       link: '/components/tabs'
    //     },
    //     {
    //       id: 66,
    //       target: 'timeline',
    //       isBookmarked: false,
    //       title: 'Timeline',
    //       icon: 'more-vertical',
    //       link: '/components/timeline'
    //     },
    //     {
    //       id: 67,
    //       target: 'toasts',
    //       isBookmarked: false,
    //       title: 'Toasts',
    //       icon: 'triangle',
    //       link: '/components/toasts'
    //     },
    //     {
    //       id: 68,
    //       target: 'tooltips',
    //       isBookmarked: false,
    //       title: 'Tooltips',
    //       icon: 'message-circle',
    //       link: '/components/tooltips'
    //     },
    //     {
    //       id: 69,
    //       target: 'sweetAlert',
    //       isBookmarked: false,
    //       title: 'Sweet Alerts',
    //       icon: 'alert-triangle',
    //       link: '/extensions/sweet-alerts'
    //     },
    //     {
    //       id: 70,
    //       target: 'blockui',
    //       isBookmarked: false,
    //       title: 'BlockUI',
    //       icon: 'loader',
    //       link: '/extensions/blockui'
    //     },
    //     {
    //       id: 71,
    //       target: 'toastr',
    //       isBookmarked: false,
    //       title: 'Toastr',
    //       icon: 'credit-card',
    //       link: '/extensions/toastr'
    //     },
    //     {
    //       id: 72,
    //       target: 'slider',
    //       isBookmarked: false,
    //       title: 'Slider',
    //       icon: 'sliders',
    //       link: '/extensions/noui-slider'
    //     },
    //     {
    //       id: 73,
    //       target: 'drag_&_drop',
    //       isBookmarked: false,
    //       title: 'Drag & Drop',
    //       icon: 'move',
    //       link: '/extensions/drag-drop'
    //     },
    //     {
    //       id: 74,
    //       target: 'tour',
    //       isBookmarked: false,
    //       title: 'Tour',
    //       icon: 'airplay',
    //       link: '/extensions/tour'
    //     },
    //     {
    //       id: 75,
    //       target: 'clipBoard',
    //       isBookmarked: false,
    //       title: 'Clipboard',
    //       icon: 'clipboard',
    //       link: '/extensions/clipboard'
    //     },
    //     {
    //       id: 76,
    //       target: 'mediaPlayer',
    //       isBookmarked: false,
    //       title: 'Media Player',
    //       icon: 'film',
    //       link: '/extensions/media-player'
    //     },
    //     {
    //       id: 77,
    //       target: 'contentMenu',
    //       isBookmarked: false,
    //       title: 'Context Menu',
    //       icon: 'menu',
    //       link: '/extensions/context-menu'
    //     },
    //     {
    //       id: 78,
    //       target: 'swiper',
    //       isBookmarked: false,
    //       title: 'Swiper',
    //       icon: 'smartphone',
    //       link: '/extensions/swiper'
    //     },
    //     {
    //       id: 79,
    //       target: 'tree-view',
    //       isBookmarked: false,
    //       title: 'Tree View',
    //       icon: 'star',
    //       link: '/extensions/tree-view'
    //     },
    //     {
    //       id: 80,
    //       target: 'i18n',
    //       isBookmarked: false,
    //       title: 'I18n',
    //       icon: 'globe',
    //       link: '/extensions/i18n'
    //     },
    //     {
    //       id: 81,
    //       target: 'input',
    //       isBookmarked: false,
    //       title: 'Input',
    //       icon: 'server',
    //       link: '/forms/form-elements/input'
    //     },
    //     {
    //       id: 82,
    //       target: 'inputGroup',
    //       isBookmarked: false,
    //       title: 'Input Group',
    //       icon: 'package',
    //       link: '/forms/form-elements/input-groups'
    //     },
    //     {
    //       id: 83,
    //       target: 'inputMask',
    //       isBookmarked: false,
    //       title: 'Input Mask',
    //       icon: 'copy',
    //       link: '/forms/form-elements/input-mask'
    //     },
    //     {
    //       id: 84,
    //       target: 'textarea',
    //       isBookmarked: false,
    //       title: 'Textarea',
    //       icon: 'edit-2',
    //       link: '/forms/form-elements/textarea'
    //     },
    //     {
    //       id: 85,
    //       target: 'checkbox',
    //       isBookmarked: false,
    //       title: 'Checkbox',
    //       icon: 'check-square',
    //       link: '/forms/form-elements/checkbox'
    //     },
    //     {
    //       id: 86,
    //       target: 'radio',
    //       isBookmarked: false,
    //       title: 'Radio',
    //       icon: 'stop-circle',
    //       link: '/forms/form-elements/radio'
    //     },
    //     {
    //       id: 87,
    //       target: 'switch',
    //       isBookmarked: false,
    //       title: 'Switch',
    //       icon: 'toggle-left',
    //       link: '/forms/form-elements/switch'
    //     },
    //     {
    //       id: 88,
    //       target: 'select',
    //       isBookmarked: false,
    //       title: 'Select',
    //       icon: 'server',
    //       link: '/forms/form-elements/select'
    //     },
    //     {
    //       id: 89,
    //       target: 'numberInput',
    //       isBookmarked: false,
    //       title: 'Number Input',
    //       icon: 'plus',
    //       link: '/forms/form-elements/number-input'
    //     },
    //     {
    //       id: 90,
    //       target: 'fileUploader',
    //       isBookmarked: false,
    //       title: 'File Uploader',
    //       icon: 'upload',
    //       link: '/forms/form-elements/file-uploader'
    //     },
    //     {
    //       id: 91,
    //       target: 'quillEditor',
    //       isBookmarked: false,
    //       title: 'Quill Editor',
    //       icon: 'type',
    //       link: '/forms/form-elements/quill-editor'
    //     },
    //     {
    //       id: 108,
    //       target: 'flatPicker',
    //       isBookmarked: false,
    //       title: 'Flatpicker',
    //       icon: 'calendar',
    //       link: '/forms/form-elements/flatpickr'
    //     },
    //     {
    //       id: 92,
    //       target: 'date_&_timePicker',
    //       isBookmarked: false,
    //       title: 'Date & Time Picker',
    //       icon: 'calendar',
    //       link: '/forms/form-elements/date-time-picker'
    //     },
    //     {
    //       id: 93,
    //       target: 'formLayouts',
    //       isBookmarked: false,
    //       title: 'Form Layouts',
    //       icon: 'layout',
    //       link: '/forms/form-layout'
    //     },
    //     {
    //       id: 94,
    //       target: 'wizard',
    //       isBookmarked: false,
    //       title: 'Form Wizard',
    //       icon: 'sliders',
    //       link: '/forms/form-wizard'
    //     },
    //     {
    //       id: 95,
    //       target: 'form-validation',
    //       isBookmarked: false,
    //       title: 'Form Validation',
    //       icon: 'thumbs-up',
    //       link: '/forms/form-validation'
    //     },
    //     {
    //       id: 96,
    //       target: 'form-repeater',
    //       isBookmarked: false,
    //       title: 'Form Repeater',
    //       icon: 'rotate-cw',
    //       link: '/forms/form-repeater'
    //     },
    //     {
    //       id: 97,
    //       target: 'tables-table',
    //       isBookmarked: false,
    //       title: 'Table',
    //       icon: 'grid',
    //       link: '/tables/table'
    //     },
    //     {
    //       id: 98,
    //       target: 'tables-datatable',
    //       isBookmarked: false,
    //       title: 'DataTables',
    //       icon: 'grid',
    //       link: '/tables/datatables'
    //     },
    //     {
    //       id: 99,
    //       target: 'apex',
    //       isBookmarked: false,
    //       title: 'Apex Charts',
    //       icon: 'bar-chart',
    //       link: '/charts-and-maps/apex'
    //     },
    //     {
    //       id: 100,
    //       target: 'chartJs',
    //       isBookmarked: false,
    //       title: 'ChartJS',
    //       icon: 'activity',
    //       link: '/charts-and-maps/chartjs'
    //     },
    //     {
    //       id: 101,
    //       target: 'google-maps',
    //       isBookmarked: false,
    //       title: 'Google Maps',
    //       icon: 'map',
    //       link: '/charts-and-maps/google-maps'
    //     },
    //     {
    //       id: 102,
    //       target: 'accordion',
    //       isBookmarked: false,
    //       title: 'Accordion',
    //       icon: 'minimize',
    //       link: '/components/accordion'
    //     },
    //     {
    //       id: 103,
    //       target: 'layout-collapsed-menu',
    //       isBookmarked: false,
    //       title: 'Collapsed Menu',
    //       icon: 'layout',
    //       link: '/ui/page-layouts/collapsed-menu'
    //     },
    //     {
    //       id: 104,
    //       target: 'layout-boxed',
    //       isBookmarked: false,
    //       title: 'Boxed Layout',
    //       icon: 'layout',
    //       link: '/ui/page-layouts/boxed-layout'
    //     },
    //     {
    //       id: 105,
    //       target: 'layout-without-menu',
    //       isBookmarked: false,
    //       title: 'Without Menu',
    //       icon: 'layout',
    //       link: '/ui/page-layouts/without-menu'
    //     },
    //     {
    //       id: 106,
    //       target: 'layout-empty',
    //       isBookmarked: false,
    //       title: 'Layout Empty',
    //       icon: 'layout',
    //       link: '/ui/page-layouts/layout-empty'
    //     },
    //     {
    //       id: 107,
    //       target: 'layout-blank',
    //       isBookmarked: false,
    //       title: 'Layout Blank',
    //       icon: 'layout',
    //       link: '/ui/page-layouts/layout-blank'
    //     }
    //   ]
    // },
    // {
    //   groupTitle: 'Files',
    //   searchLimit: 4,
    //   data: [
    //     {
    //       title: 'Passport Image',
    //       by: 'Oliver Queen',
    //       size: '52kb',
    //       file: 'assets/images/icons/jpg.png'
    //     },
    //     {
    //       title: 'Parenting Guide',
    //       by: 'Alfred Pennyworth',
    //       size: '2.3mb',
    //       file: 'assets/images/icons/doc.png'
    //     },
    //     {
    //       title: 'Class Notes',
    //       by: 'Barry Allen',
    //       size: '30kb',
    //       file: 'assets/images/icons/doc.png'
    //     },
    //     {
    //       title: 'Class Attendance',
    //       by: 'Walter White',
    //       size: '52mb',
    //       file: 'assets/images/icons/xls.png'
    //     }
    //   ]

    // }
    
  ];
}
