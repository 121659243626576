import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { error } from 'console';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // Public
  public search = '';
  public apiData:any = [];
  public onApiDataChange: BehaviorSubject<any>;
  public onIsBookmarkOpenChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
    this.onIsBookmarkOpenChange = new BehaviorSubject(false);    
  }

  addNewPatient(data): Promise<any[]> {
    return new Promise((resolve) => {
    this._httpClient.post(`${environment.apiUrl}/member`, 
    data).subscribe((response: any) => {
      resolve(response);
    }, error => {
        Swal.fire({
          title: 'Failed!',
          html:`<div><p>${error}</p></div>`,
          showCancelButton: false,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        })
    });
  })};

  /**
   * Get Search Data
   */
  getSearchData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/membership`).subscribe((res: any) => {
        this.apiData.data = [];        
        res.forEach(element => {
          this.apiData[0].data.push(            
            {
              'date': new Date(element.memberCreatedAt).toISOString().slice(0, 10),
              'email': element.memberEmail,
              'phoneNumber': element.phoneNumber,
              'img': "",
              'title': element.memberFullName,
              'status': element.status,
              "firstName": element.firstName,
              "lastName": element.lastName,
              "documentNumber": element.documentNumber, 
              "DoB": element.DoB,
              "gender": element.gender,
              "address": element.address,
              "countryAreaCode": element.countryAreaCode,
              'id': element.memberId,
              'public_id': element.public_id
            }
          )
        });        

        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);

      this._httpClient.get('api/search-data').subscribe((response: any) => {
        this.apiData = response;                
        this.onApiDataChange.next(this.apiData);

        resolve(this.apiData);
      }, reject);
    });
  }
}
