import { Component, OnInit, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../main/apps/member/member-search/search.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CustomerService } from '../../../../../services/customer.service';


@Component({
  selector: 'app-navbar-invite',
  templateUrl: './navbar-invite.component.html'
})
export class NavbarInviteComponent implements OnInit {

  // Public
  public inviteContent :any = [];
  public locations;
  public selectLocation;
  public currentLocationName;
  public today = new Date().toISOString().slice(0, 10);


  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {EcommerceService} _ecommerceService
   * @param {CommunicationService} _communicationService
   */
  constructor(
    private modalService: NgbModal,
    public _searchService: SearchService,
    private _customersService: CustomerService
    ) {
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {    

  }

  /**
  * Send New Contact
  */
  openSender(modalLG): void {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'))            

    if(currentUser){
      this._customersService
        .getLocations()
        .subscribe(
          data => {                       
            this.locations = data; 
            for (let i = 0; i < currentUser.location.length; i++) {
              if (currentUser.location[i].id == currentUser.sessionLocation) {
                this.currentLocationName = currentUser.location[i].locationName;
              }
            };
          },
          error => {
            //this.loading = false;
          }
        );

    }

    this.inviteContent =
      {
        firstName: '',
        lastName: '',
        contact: '',
        phoneNumber:'',
        address:'',
        birthday: this.today,
        gender:''
      };

    this.modalService.open(modalLG, {
      centered: true,
      size: 'lg'

    });
}

  createMember(){

    var data = {
      firstName: this.inviteContent.firstName,
      lastName: this.inviteContent.lastName,
      phoneNumber: this.inviteContent.phoneNumber,
      email: this.inviteContent.email,
      dob: this.inviteContent.birthday,
      address: this.inviteContent.address,
      gender: this.inviteContent.gender,
      documentNumber: this.inviteContent.phoneNumber + this.inviteContent.birthday
    }
             
    this._searchService.addNewPatient(data).then(result=> {        
      Swal.fire({
        title: 'Success!',
        html: '<div><p>You added a new patient without membership plan. </p></div>',
        showCancelButton: false,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      }).then(res=>{
        Swal.close()             
        location.replace('/apps/member/member-view/'+ String(result['id']))    
      });


    });
  }   

    
  
}
