export class accountSettingsFakeData {
  public static data = {
    accountSetting: {
      general: {
        logo: 'https://i.imgur.com/necjdOW.jpg',
        company: 'ArtSmiles General & Cosmetic Dentistry',
        companyId: '000.000.00',
        email: 'contact@artsmile.com.au',
        address: '4/45-49 Nind St, Southport QLD',
        country: 'Australia',
        phone: '(+656) 254 2568',
      },
      info: {
        bio: '',
        website: '',
        email: 'contact@artsmile.com.au',
        phone: '(+656) 254 2568'
      },
      social: {
        socialLinks: {
          twitter: 'https://www.twitter.com',
          facebook: '',
          google: '',
          linkedIn: 'https://www.linkedin.com',
          instagram: '',
          quora: ''
        },
        connections: {
          twitter: {
            profileImg: 'assets/images/avatars/11-small.png',
            id: '@johndoe'
          },
          google: {
            profileImg: 'assets/images/avatars/3-small.png',
            id: '@luraweber'
          },
          facebook: {},
          github: {}
        }
      },
      notification: {
        commentOnArticle: true,
        AnswerOnForm: true,
        followMe: false,
        newAnnouncements: true,
        productUpdates: true,
        blogDigest: false
      },
      config:{
        userRoles: ['Admin', 'User']
      }
    }
  };
}
