import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // Public
  public search = '';
  public apiData:any = [];
  public onApiDataChange: BehaviorSubject<any>;
  public onIsBookmarkOpenChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
    this.onIsBookmarkOpenChange = new BehaviorSubject(false);    
  }

  /**
   * Get Search Data
   */
  getSearchData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/membership`).subscribe((res: any) => {
        this.apiData[0].data = [];
        res.forEach(element => {
          this.apiData[0].data.push(            
            {
              'date': new Date(element.memberCreatedAt).toLocaleDateString(),
              'email': element.memberEmail,
              'img': "",
              'title': element.memberFullName,
              'status': element.status,
              'link': '/apps/member/member-view/' + element.memberId
            }
          )
        });        

        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);

      this._httpClient.get('api/search-data').subscribe((response: any) => {
        this.apiData = response;                
        this.onApiDataChange.next(this.apiData);

        resolve(this.apiData);
      }, reject);
    });
  }
}
