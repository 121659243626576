import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);  
    const isTest = window.location.href.slice(0, 16) == 'http://localhost' || window.location.href.slice(0, 16) == 'https://test-smi';     
    var link = window.location.href;  
      

    if (isLoggedIn && isApiUrl) {  
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,

        }
      });
  
      
      if (isTest) {  
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
            'X-Data-Source': 'test'
          }
        });
      }
    }else{
      if (isTest) {  
        request = request.clone({
          setHeaders: {
            'X-Data-Source': 'test'
          }
        });
      }

    }





    return next.handle(request);
  }
}
