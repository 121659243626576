<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
   
    <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- Action Dropdown -->
    <li ngbDropdown class="nav-item">
      <a
        class="nav-link dropdown-toggle nav-link-style btn"
        id="dropdown-action"
        ngbDropdownToggle
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span [data-feather]="'user-plus'" class="ficon font-medium-5 feather"></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-left">
        <a ngbDropdownItem [routerLink]="['/apps/member/member-new']">
          <span [class]="'mr-50'"></span> Add New Member
        </a>
        <a ngbDropdownItem [routerLink]="['/apps/member/member-payment']" >
          <span [class]="'mr-50'"></span> Create Payment
        </a>
      </div>
    </li>
    <!--/ Action Dropdown -->
      
      <!-- Invite -->
      <app-navbar-invite></app-navbar-invite>
      <!--/ Invite -->

      <!-- Search -->
      <app-navbar-search></app-navbar-search>
      <!--/ Search -->

    </ul>
    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->

  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->
    

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    
    <!-- Action Dropdown -->
    <li ngbDropdown class="nav-item">
      <a
        class="nav-link dropdown-toggle nav-link-style btn"
        id="dropdown-action"
        ngbDropdownToggle
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span [data-feather]="'map-pin'" class="ficon font-medium-5 feather"></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-left">
        <a ngbDropdownItem  href="javascript:void(0);" [disabled]="location.locationStatus != 'active'" (click)="setLocation(location.id)" *ngFor="let location of this.currentUser.location">
          <span [ngClass]="currentUser.sessionLocation == location.id ? 'icon-check-circle' : 'icon-circle'" class="ficon font-medium-5 feather"></span>

           {{location.locationName}}
        </a>
      </div>
    </li>
    <!--/ Action Dropdown -->
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              >{{ this.currentUser.firstName }} {{ this.currentUser.lastName }}</span
            ><span class="user-status">{{ this.currentUser.role }}</span>
          </div>
            <div
              class="avatar mr-1 ml-0"
              [ngClass]="{
                'bg-light-success': currentUser.status == 'active',
                'bg-light-secondary': currentUser.status == 'inactive',
                'bg-light-warning': currentUser.status == 'pending'
              }"
            >
              <div class="avatar-content">{{ currentUser.firstName | initials }}</div>
            </div>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['pages/account-settings']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['/pages/support']"
          ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> Support</a
        ><a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
