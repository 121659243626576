export class DashboardFakeData {
  public static data = {
    
    homeChart: {
      analyticsData:[
        {
          title: 'Members',
          value: 204, // Displays the number of members according to the selected location
          history: {
            value: '+6.9%',// Based on the previous month, how much the number of members has increased
            description: 'this month'
          },
          goal:{
            value: (204/230*100), 
            description: 'Goal of 230'
          }
        },
        {
          title: 'Retention',
          value: '88%',
          history: {
            value: '+6.9%',
            description: 'this month'
          },
          goal:{
            value: 95,
            description: 'Goal of 95%'
          }
        },
        {
          title: 'Total Revenue',
          value: '$162,492',
          history: {
            value: '+8.4%',
            description: 'this month'
          },
          goal:{
            value: (8.4/10*100),
            description: 'Goal of 10%/month'
          }
        }
      ],
      lineChartsData:[
        {
          title: 'New Members',
          categories: [
            'March',
            'April',
            'May',
            'June',
            'July',
            'August'
          ],
          append:'',
          prepend:'',
          data: [24,19, 25, 21, 37, 37]
        },
        {
          title: 'Commited Payments ($)',
          categories: [
            'March',
            'April',
            'May',
            'June',
            'July',
            'August'
          ],
          append:'',
          prepend:'$',
          data: [5000.00 ,5500.00, 5200.00, 7000.00, 8000.00, 8300.00]
        }
      ],
      lastNewMembers:[
        {
          type: "new_member",
          created_at: "August 13, 8:15am",
          description: "Johnny Cash joined as a Single Member at  Smily Clinic Southport."
        },
        {
          type: "new_member",
          created_at: "August 11, 3:57pm",
          description: "Lindsey Williamson joined as a Couple Member at  Smily Clinic Benowa."
        },
        {
          type: "new_member",
          created_at: "August 11, 3:57pm",
          description: "Brad Williamson joined as a Couple Member at  Smily Clinic Benowa."
        },
        {
          type: "new_member",
          created_at: "August 9, 7:20am",
          description: "Peter Smith joined as a Single Member at  Smily Clinic Southport."
        },
        {
          type: "new_member",
          created_at: "August 4, 8:15am",
          description: "Mia Brown joined as a Single Member at  Smily Clinic Burleigh."
        },
        {
          type: "new_member",
          created_at: "July 27, 8:15am",
          description: "Hayley Adams joined as a Single Member at  Smily Clinic Benowa."
        },
        {
          type: "new_member",
          created_at: "July 25, 9:45am",
          description: "Peter Adams joined as a Single Member at  Smily Clinic Benowa."
        }
      ],
      activityReport:{
        chartSeries:[135, 16, 53], //Active, suspended, cancelled?
        budgetChart: {
          series: [
            {
              data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62]
            },
            {
              data: [70, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27]
            }
          ]
        },
        analyticsData: {
          currentBudget: '$25,852',
          totalBudget: '56,800'
        }
      }
    },

    // subscribers_gained: {
    //   series: [
    //     {
    //       name: 'Subscribers',
    //       data: [28, 40, 36, 52, 38, 60, 55]
    //     }
    //   ],
    //   analyticsData: {
    //     subscribers: '92.6k'
    //   }
    // },
    // ordersRecevied: {
    //   series: [
    //     {
    //       name: 'Orders',
    //       data: [10, 15, 8, 15, 7, 12, 8]
    //     }
    //   ],
    //   analyticsData: {
    //     orders: '38.4k'
    //   }
    // },
    // avgSessions: {
    //   series: [
    //     {
    //       name: 'Sessions',
    //       data: [75, 125, 225, 175, 125, 75, 25]
    //     }
    //   ],
    //   analyticsData: {
    //     avgSessions: '2.7k',
    //     vsLastSevenDays: '+5.2%',
    //     goal: '$100000',
    //     goalProgressbar: 50,
    //     retention: '90%',
    //     retentionProgressbar: 60,
    //     users: '100k',
    //     usersProgressbar: 70,
    //     duration: '1yr',
    //     durationProgressbar: 90
    //   }
    // },
    // supportTracker: {
    //   series: [83],
    //   analyticsData: {
    //     tickets: 163,
    //     newTickets: 29,
    //     openTickets: 63,
    //     responseTime: '1d'
    //   }
    // },
    // salesLastSixMonths: {
    //   series: [
    //     {
    //       name: 'Sales',
    //       data: [90, 50, 86, 40, 100, 20]
    //     },
    //     {
    //       name: 'Visit',
    //       data: [70, 75, 70, 76, 20, 85]
    //     }
    //   ]
    // },
    // statistics: {
    //   analyticsData: {
    //     sales: '230k',
    //     customers: '8.549k',
    //     products: '1.423k',
    //     revenue: '$9745'
    //   }
    // },
    // ordersChart: {
    //   series: [
    //     {
    //       name: '2020',
    //       data: [45, 85, 65, 45, 65]
    //     }
    //   ],
    //   analyticsData: {
    //     orders: '2,76k'
    //   }
    // },
    // profitChart: {
    //   series: [
    //     {
    //       data: [0, 20, 5, 30, 15, 45]
    //     }
    //   ],
    //   analyticsData: {
    //     profit: '6,24k'
    //   }
    // },
    revenueReport: {
      budgetChart: {
        range:{
          from: {day: 1, month: 4, year: 2023},
          to: {day: 7, month: 4, year: 2023},
          Description: 'Last 7 days'
        },
        series: [
          {
            data: [6, 8, 9, 2, 6, 4, 9]
          },
          {
            data: [0, 1, 3, 4, 2, 0, 2]
          }
        ]
      },
      analyticsData: {
        newMembers: 44,
        cancelations: 11
      }
    },
    // goalOverview: {
    //   series: [83],
    //   analyticsData: {
    //     completed: '786,617',
    //     inProgress: '13,561'
    //   }
    // }
  };
}
