import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get customer by id 
   */
  getById() {
    const token = localStorage.getItem('token');

    return this._http.get(`${environment.apiUrl}/customer`);
  }

  /**
 * Get customer by id 
 */
    updateById(data) {
      return new Promise((resolve, reject) => {            
        this._http.patch(`${environment.apiUrl}/customer/${data.id}`, data).subscribe((response: any) => {
          resolve(response);
        }, reject);       
      });
    }
  /**
 * Get locations by customer id 
 */
    getLocations() {
      return this._http.get(`${environment.apiUrl}/location`);
    }

      /**
 * Activate Location 
 */
      activateLocation(id, return_link) {
        return new Promise((resolve, reject) => {            

          this._http.post(`${environment.apiUrl}/location/${id}/activate`, return_link).subscribe((response: any) => {
            resolve(response);
          }, reject);       
        });
      }

            /**
 * Activate Location 
 */
    inactivateLocation(id) {
      return new Promise((resolve, reject) => {            

        this._http.patch(`${environment.apiUrl}/location/${id}/inactivate`, id).subscribe((response: any) => {
          resolve(response);
        }, reject);       
      });
    }

                /**
 * Create Location Link
 */
    createLocationLink(info) {      
      return new Promise((resolve, reject) => {            
        this._http.post(`${environment.apiUrl}/campaign`, info).subscribe((response: any) => {
          resolve(response);
        }, reject);       
      });
    }  

                    /**
 * Delete Location Link
 */
    deleteLocationLink(token) {      
      return new Promise((resolve, reject) => {            
        this._http.delete(`${environment.apiUrl}/campaign/${token}`).subscribe((response: any) => {
          resolve(response);
        }, reject);       
      });
    }  



      /**
 * Get locations by customer id 
 */
       getUsersList() {
        return this._http.get(`${environment.apiUrl}/user`);
      }
  

}