export class DatatableCodeData {
  public static rows = [
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '011',
      procedure: "Comprehensive oral examination",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '012',
      procedure: "Periodic oral examination",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '013',
      procedure: "Oral examination – limited",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '014',
      procedure: "Consultation",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '015',
      procedure: "Consultation – extended (30 minutes or more)",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '016',
      procedure: "Consultation by referral",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '017',
      procedure: "Consultation by referral – extended (30 minutes or more)",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '018',
      procedure: "Written report (not elsewhere included)",
    },
    {
      category:'Diagnostic Services',
      group:'Examinations',
      code: '019',
      procedure: "Letter of referral",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '022',
      procedure: "Intraoral periapical or bitewing radiograph – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '025',
      procedure: "Intraoral radiograph – occlusal, maxillary, mandibular – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '026',
      procedure: "Cone Beam Volumetric Tomography – scan acquisition – per appointment",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '031',
      procedure: "Extraoral radiograph – maxillary, mandibular – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '033',
      procedure: "Lateral, antero-posterior, postero-anterior or submento-vertex radiograph of the skull – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '035',
      procedure: "Radiograph of temporomandibular joint – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '036',
      procedure: "Cephalometric radiograph – lateral, antero-posterior, postero-anterior or submento-vertex – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '037',
      procedure: "Panoramic radiograph – per exposure",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '038',
      procedure: "Hand-wrist radiograph for skeletal age assessment",
    },
    {
      category:'Diagnostic Services',
      group:'Radiological Examination, Analysis and Interpretation',
      code: '039',
      procedure: "Computed Tomography of the skull or parts thereof",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '041',
      procedure: "Bacteriological examination",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '042',
      procedure: "Culture examination and identification",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '043',
      procedure: "Antibiotic sensitivity test",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '044',
      procedure: "Collection of specimen for pathology examination",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '047',
      procedure: "Saliva screening test",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '048',
      procedure: "Bacteriological screening test",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '051',
      procedure: "Biopsy of tissue",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '052',
      procedure: "Histopathological examination of tissue",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '053',
      procedure: "Cytological investigation",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '054',
      procedure: "Oral mucosal screening",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '055',
      procedure: "Blood sample",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '056',
      procedure: "Haematological examination",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '059',
      procedure: "Comprehensive head and neck cancer examination and risk assessment",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '061',
      procedure: "Pulp testing – per appointment",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '071',
      procedure: "Diagnostic model – per model",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '072',
      procedure: "Photographic records – intraoral – per appointment",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '073',
      procedure: "Photographic records – extraoral – per appointment",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '074',
      procedure: "Diagnostic wax-up",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '075',
      procedure: "Diagnostic modelling",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '081',
      procedure: "Cephalometric analysis – excluding radiographs",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '082',
      procedure: "Tooth–jaw size prediction analysis",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '083',
      procedure: "Tomographic analysis",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '085',
      procedure: "Electromyographic recording",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '086',
      procedure: "Electromyographic analysis",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '087',
      procedure: "Cone Beam Volumetric Tomography analysis and/or interpretation – small field of view (less than one complete dental arch)",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '088',
      procedure: "Cone Beam Volumetric Tomography analysis and/or interpretation – maxillary or mandibular dentition (single arch)",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '089',
      procedure: "Cone Beam Volumetric Tomography analysis and/or interpretation – maxillary and mandibular dentition (both arches)",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '090',
      procedure: "Cone Beam Volumetric Tomography analysis and/or interpretation – temporomandibular joints only",
    },
    {
      category:'Diagnostic Services',
      group:'Other Diagnostic Services',
      code: '091',
      procedure: "Cone Beam Volumetric Tomography analysis and/or interpretation – orofacial structures",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '111',
      procedure: "Removal of plaque and/or stain",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '113',
      procedure: "Recontouring and polishing of pre-existing restoration(s) – per appointment",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '114',
      procedure: "Removal of calculus – first appointment",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '115',
      procedure: "Removal of calculus – subsequent appointment",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '116',
      procedure: "Enamel micro-abrasion – per tooth",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '117',
      procedure: "Bleaching, internal – per tooth",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '118',
      procedure: "Bleaching, external – per tooth",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Dental Prophylaxis and Bleaching',
      code: '119',
      procedure: "Bleaching, home application – per arch",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Remineralisation Agents',
      code: '121',
      procedure: "Topical application of remineralisation and/or cariostatic agents, one treatment",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Remineralisation Agents',
      code: '122',
      procedure: "Topical remineralisation and/or cariostatic agents, home application – per arch",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Remineralisation Agents',
      code: '123',
      procedure: "Concentrated remineralisation and/or cariostatic agents, application – single tooth",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '131',
      procedure: "Dietary analysis and advice",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '141',
      procedure: "Oral hygiene instruction",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '142',
      procedure: "Tobacco counselling",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '151',
      procedure: "Provision of a mouthguard – indirect",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '153',
      procedure: "Bi-maxillary mouthguard – indirect",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '161',
      procedure: "Fissure and/or tooth surface sealing – per tooth",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '165',
      procedure: "Desensitising procedure – per appointment",
    },
    {
      category:'Preventive, Prophylactic and Bleaching Services',
      group:'Other Preventive Services',
      code: '171',
      procedure: "Odontoplasty – per tooth",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '213',
      procedure: "Treatment of acute periodontal infection – per appointment",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '221',
      procedure: "Clinical periodontal analysis and recording",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '222',
      procedure: "Periodontal debridement – per tooth",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '223',
      procedure: "Non-surgical treatment of peri-implant disease – per implant",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '231',
      procedure: "Gingivectomy – per tooth",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '232',
      procedure: "Periodontal flap surgery – per tooth",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '233',
      procedure: "Surgical treatment of peri-implant disease – per implant",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '234',
      procedure: "Application of biologically active material",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '235',
      procedure: "Gingival graft – per tooth, implant or extraction socket",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '236',
      procedure: "Guided tissue regeneration – per tooth or implant",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '237',
      procedure: "Guided tissue regeneration – membrane removal",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '238',
      procedure: "Periodontal surgery for crown lengthening – per tooth",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '241',
      procedure: "Root resection – per root",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '242',
      procedure: "Osseous surgery – per tooth or implant",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '243',
      procedure: "Osseous graft – per tooth or implant",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '244',
      procedure: "Osseous graft – block",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '245',
      procedure: "Periodontal surgery involving one tooth",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '246',
      procedure: "Maxillary sinus augmentation – Trans-alveolar technique – per sinus",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '247',
      procedure: "Maxillary sinus augmentation – Lateral wall approach – per sinus",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '250',
      procedure: "Active non-surgical periodontal therapy – per quadrant",
    },
    {
      category:'Periodontics',
      group:'Periodontics',
      code: '251',
      procedure: "Supportive periodontal therapy – per appointment",
    },
    {
      category:'Oral Surgery',
      group:'Extractions',
      code: '311',
      procedure: "Removal of a tooth or part(s) thereof",
    },
    {
      category:'Oral Surgery',
      group:'Extractions',
      code: '314',
      procedure: "Sectional removal of a tooth or part(s) thereof",
    },
    {
      category:'Oral Surgery',
      group:'Surgical Extractions',
      code: '322',
      procedure: "Surgical removal of a tooth or tooth fragment not requiring removal of bone or tooth division",
    },
    {
      category:'Oral Surgery',
      group:'Surgical Extractions',
      code: '323',
      procedure: "Surgical removal of a tooth or tooth fragment requiring removal of bone",
    },
    {
      category:'Oral Surgery',
      group:'Surgical Extractions',
      code: '324',
      procedure: "Surgical removal of a tooth or tooth fragment requiring both removal of bone and tooth division",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '331',
      procedure: "Alveolectomy – per segment",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '332',
      procedure: "Ostectomy – per jaw",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '337',
      procedure: "Reduction of fibrous tuberosity",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '338',
      procedure: "Reduction of flabby ridge – per segment",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '341',
      procedure: "Removal of hyperplastic tissue",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '343',
      procedure: "Repositioning of muscle attachment",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '344',
      procedure: "Vestibuloplasty",
    },
    {
      category:'Oral Surgery',
      group:'Surgery for Prostheses',
      code: '345',
      procedure: "Skin or mucosal graft",
    },
    {
      category:'Oral Surgery',
      group:'Treatment of Maxillofacial injuries',
      code: '351',
      procedure: "Repair of skin and subcutaneous tissue or mucous membrane",
    },
    {
      category:'Oral Surgery',
      group:'Treatment of Maxillofacial injuries',
      code: '352',
      procedure: "Fracture of maxilla or mandible – not requiring fixation",
    },
    {
      category:'Oral Surgery',
      group:'Treatment of Maxillofacial injuries',
      code: '353',
      procedure: "Fracture of maxilla or mandible – with wiring of teeth or intraoral fixation",
    },
    {
      category:'Oral Surgery',
      group:'Treatment of Maxillofacial injuries',
      code: '354',
      procedure: "Fracture of maxilla or mandible – with external fixation",
    },
    {
      category:'Oral Surgery',
      group:'Treatment of Maxillofacial injuries',
      code: '355',
      procedure: "Fracture of zygoma",
    },
    {
      category:'Oral Surgery',
      group:'Treatment of Maxillofacial injuries',
      code: '359',
      procedure: "Fracture of the maxilla or mandible requiring open reduction",
    },
    {
      category:'Oral Surgery',
      group:'Dislocations',
      code: '361',
      procedure: "Mandible – relocation following dislocation",
    },
    {
      category:'Oral Surgery',
      group:'Dislocations',
      code: '363',
      procedure: "Mandible – relocation requiring open operation",
    },
    {
      category:'Oral Surgery',
      group:'Osteotomies',
      code: '365',
      procedure: "Osteotomy – maxilla",
    },
    {
      category:'Oral Surgery',
      group:'Osteotomies',
      code: '366',
      procedure: "Osteotomy – mandible",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '371',
      procedure: "Removal of tumour, cyst or scar – cutaneous, subcutaneous or in mucous membrane",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '373',
      procedure: "Removal of tumour, cyst or scar involving muscle, bone or other deep tissue",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '375',
      procedure: "Surgery to salivary duct",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '376',
      procedure: "Surgery to salivary gland",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '377',
      procedure: "Removal or repair of soft tissue (not elsewhere defined)",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '378',
      procedure: "Surgical removal of foreign body",
    },
    {
      category:'Oral Surgery',
      group:'General Surgical',
      code: '379',
      procedure: "Marsupialisation of cyst",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '381',
      procedure: "Surgical exposure of unerupted tooth – per tooth",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '382',
      procedure: "Surgical exposure and attachment of device for orthodontic traction",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '384',
      procedure: "Repositioning of displaced tooth/teeth – per tooth",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '385',
      procedure: "Surgical repositioning of unerupted tooth – per tooth",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '386',
      procedure: "Splinting of displaced tooth/teeth – per tooth",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '387',
      procedure: "Replantation and splinting of a tooth – per tooth",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '388',
      procedure: "Transplantation of tooth or tooth bud",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '389',
      procedure: "Surgery to isolate and preserve neurovascular tissue",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '391',
      procedure: "Frenectomy",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '392',
      procedure: "Drainage of abscess",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '393',
      procedure: "Surgery involving the maxillary antrum",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '394',
      procedure: "Surgery for osteomyelitis",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '395',
      procedure: "Repair of nerve trunk",
    },
    {
      category:'Oral Surgery',
      group:'Other Surgical Procedures',
      code: '399',
      procedure: "Control of reactionary or secondary post-operative haemorrhage",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '411',
      procedure: "Direct pulp capping",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '412',
      procedure: "Incomplete endodontic therapy (tooth not suitable for further treatment)",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '414',
      procedure: "Pulpotomy",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '415',
      procedure: "Complete chemo-mechanical preparation of root canal – one canal",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '416',
      procedure: "Complete chemo-mechanical preparation of root canal – each additional canal",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '417',
      procedure: "Root canal obturation – one canal",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '418',
      procedure: "Root canal obturation – each additional canal",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '419',
      procedure: "Extirpation of pulp or debridement of root canal(s) – emergency or palliative",
    },
    {
      category:'Endodontics',
      group:'Pulp and Root Canal Treatments',
      code: '421',
      procedure: "Resorbable root canal filling – primary tooth",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '431',
      procedure: "Periapical curettage – per root",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '432',
      procedure: "Apicectomy – per root",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '433',
      procedure: "Exploratory periradicular surgery",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '434',
      procedure: "Apical seal – per canal",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '436',
      procedure: "Sealing of perforation",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '437',
      procedure: "Surgical treatment and repair of external root resorption – per tooth",
    },
    {
      category:'Endodontics',
      group:'Periradicular Surgery',
      code: '438',
      procedure: "Hemisection",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '445',
      procedure: "Exploration and/or negotiation of a calcified canal – per canal, per appointment",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '451',
      procedure: "Removal of root filling – per canal",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '452',
      procedure: "Removal of a cemented root canal post or post crown",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '453',
      procedure: "Removal or bypassing fractured endodontic instrument",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '455',
      procedure: "Additional visit for irrigation and/or dressing of the root canal system – per tooth",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '457',
      procedure: "Obturation of resorption defect or perforation (non-surgical)",
    },
    {
      category:'Endodontics',
      group:'Other Endodontic Services',
      code: '458',
      procedure: "Interim therapeutic root filling – per tooth",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Direct',
      code: '511',
      procedure: "Metallic restoration – one surface – direct",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Direct',
      code: '512',
      procedure: "Metallic restoration – two surfaces – direct",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Direct',
      code: '513',
      procedure: "Metallic restoration – three surfaces – direct",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Direct',
      code: '514',
      procedure: "Metallic restoration – four surfaces – direct",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Direct',
      code: '515',
      procedure: "Metallic restoration – five surfaces – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Anterior Teeth – Direct',
      code: '521',
      procedure: "Adhesive restoration – one surface – anterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Anterior Teeth – Direct',
      code: '522',
      procedure: "Adhesive restoration – two surfaces – anterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Anterior Teeth – Direct',
      code: '523',
      procedure: "Adhesive restoration – three surfaces – anterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Anterior Teeth – Direct',
      code: '524',
      procedure: "Adhesive restoration – four surfaces – anterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Anterior Teeth – Direct',
      code: '525',
      procedure: "Adhesive restoration – five surfaces – anterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Posterior Teeth – Direct',
      code: '531',
      procedure: "Adhesive restoration – one surface – posterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Posterior Teeth – Direct',
      code: '532',
      procedure: "Adhesive restoration – two surfaces – posterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Posterior Teeth – Direct',
      code: '533',
      procedure: "Adhesive restoration – three surfaces – posterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Posterior Teeth – Direct',
      code: '534',
      procedure: "Adhesive restoration – four surfaces – posterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Adhesive Restorations – Posterior Teeth – Direct',
      code: '535',
      procedure: "Adhesive restoration – five surfaces – posterior tooth – direct",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Indirect',
      code: '541',
      procedure: "Metallic restoration – one surface – indirect",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Indirect',
      code: '542',
      procedure: "Metallic restoration – two surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Indirect',
      code: '543',
      procedure: "Metallic restoration – three surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Indirect',
      code: '544',
      procedure: "Metallic restoration – four surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Metallic Restorations – Indirect',
      code: '545',
      procedure: "Metallic restoration – five surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Tooth-coloured Restorations – Indirect',
      code: '551',
      procedure: "Tooth-coloured restoration – one surface – indirect",
    },
    {
      category:'Restorative Services',
      group:'Tooth-coloured Restorations – Indirect',
      code: '552',
      procedure: "Tooth-coloured restoration – two surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Tooth-coloured Restorations – Indirect',
      code: '553',
      procedure: "Tooth-coloured restoration – three surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Tooth-coloured Restorations – Indirect',
      code: '554',
      procedure: "Tooth-coloured restoration – four surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Tooth-coloured Restorations – Indirect',
      code: '555',
      procedure: "Tooth-coloured restoration – five surfaces – indirect",
    },
    {
      category:'Restorative Services',
      group:'Other Restorative Services',
      code: '571',
      procedure: "Adaptation of new restoration to existing removable prosthesis – per tooth",
    },
    {
      category:'Restorative Services',
      group:'Other Restorative Services',
      code: '572',
      procedure: "Provisional (intermediate/temporary) restoration – per tooth",
    },
    {
      category:'Restorative Services',
      group:'Other Restorative Services',
      code: '574',
      procedure: "Metal band",
    },
    {
      category:'Restorative Services',
      group:'Other Restorative Services',
      code: '575',
      procedure: "Pin retention – per pin",
    },
    {
      category:'Restorative Services',
      group:'Other Restorative Services',
      code: '577',
      procedure: "Cusp capping – per cusp",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '611',
      procedure: "Full crown – acrylic resin – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '613',
      procedure: "Full crown – non-metallic – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '615',
      procedure: "Full crown – veneered – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '618',
      procedure: "Full crown – metallic – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '625',
      procedure: "Post and core for crown – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '627',
      procedure: "Preliminary restoration for crown – direct",
    },
    {
      category:'Prosthodontics',
      group:'Crowns',
      code: '629',
      procedure: "Post and root cap – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Provisional Crown and Bridge',
      code: '631',
      procedure: "Provisional crown – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Provisional Crown and Bridge',
      code: '632',
      procedure: "Provisional bridge pontic – per pontic",
    },
    {
      category:'Prosthodontics',
      group:'Provisional Crown and Bridge',
      code: '633',
      procedure: "Provisional implant abutment – per abutment",
    },
    {
      category:'Prosthodontics',
      group:'Provisional Crown and Bridge',
      code: '634',
      procedure: "Provisional implant restoration – per implant abutment",
    },
    {
      category:'Prosthodontics',
      group:'Bridges',
      code: '642',
      procedure: "Bridge pontic – direct – per pontic",
    },
    {
      category:'Prosthodontics',
      group:'Bridges',
      code: '643',
      procedure: "Bridge pontic – indirect – per pontic",
    },
    {
      category:'Prosthodontics',
      group:'Bridges',
      code: '644',
      procedure: "Semi-fixed attachment",
    },
    {
      category:'Prosthodontics',
      group:'Bridges',
      code: '645',
      procedure: "Precision or magnetic attachment",
    },
    {
      category:'Prosthodontics',
      group:'Bridges',
      code: '649',
      procedure: "Retainer for bonded fixture – indirect – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '651',
      procedure: "Recementing crown or veneer",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '652',
      procedure: "Recementing bridge or splint – per abutment",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '653',
      procedure: "Rebonding of bridge or splint where retreatment of bridge surface is required",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '655',
      procedure: "Removal of crown",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '656',
      procedure: "Removal of bridge or splint",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '658',
      procedure: "Repair of crown, bridge or splint – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Crown and Bridge Repairs and Other Services ',
      code: '659',
      procedure: "Repair of crown, bridge or splint – direct",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '661',
      procedure: "Fitting of implant abutment – per abutment",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '663',
      procedure: "Removal of implant and/or retention device",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '664',
      procedure: "Fitting of bar for denture – per abutment",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '665',
      procedure: "Prosthesis with resin base attached to implants – removable – per arch",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '666',
      procedure: "Prosthesis with metal frame attached to implants – fixed – per arch",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '667',
      procedure: "Prosthesis with metal frame attached to implants – removable – per arch",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '668',
      procedure: "Fixture or abutment screw removal and replacement",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '669',
      procedure: "Removal and reattachment of prosthesis fixed to implant(s) – perimplant",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '671',
      procedure: "Full crown attached to osseointegrated implant – non-metallic –indirect",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '672',
      procedure: "Full crown attached to osseointegrated implant – veneered – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '673',
      procedure: "Full crown attached to osseointegrated implant – metallic – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '678',
      procedure: "Diagnostic template",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '679',
      procedure: "Surgical implant guide",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '684',
      procedure: "Insertion of first stage of two-stage endosseous implant – per implant",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '688',
      procedure: "Insertion of one-stage endosseous implant – per implant",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '689',
      procedure: "Provisional implant",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '690',
      procedure: "Provisional retention or anchorage device",
    },
    {
      category:'Prosthodontics',
      group:'Procedures for Implant Prostheses',
      code: '691',
      procedure: "Second stage surgery of two-stage endosseous implant – per implant",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '711',
      procedure: "Complete maxillary denture",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '712',
      procedure: "Complete mandibular denture",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '713',
      procedure: "Provisional complete maxillary denture",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '714',
      procedure: "Provisional complete mandibular denture",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '715',
      procedure: "Provisional complete maxillary and mandibular dentures",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '716',
      procedure: "Metal palate or plate",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '719',
      procedure: "Complete maxillary and mandibular dentures",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '721',
      procedure: "Partial maxillary denture – resin base",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '722',
      procedure: "Partial mandibular denture – resin base",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '723',
      procedure: "Provisional partial maxillary denture",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '724',
      procedure: "Provisional partial mandibular denture",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '727',
      procedure: "Partial maxillary denture – cast metal framework",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '728',
      procedure: "Partial mandibular denture – cast metal framework",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '730',
      procedure: "A code number for Department of Veterans’ Affairs use only",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '731',
      procedure: "Retainer – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '732',
      procedure: "Occlusal rest – per rest",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '733',
      procedure: "Tooth/teeth (partial denture)",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '734',
      procedure: "Overlays – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '735',
      procedure: "Precision or magnetic denture attachment",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '736',
      procedure: "Immediate tooth replacement – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '737',
      procedure: "Resilient lining",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '738',
      procedure: "Wrought bar",
    },
    {
      category:'Prosthodontics',
      group:'Dentures and Denture Components',
      code: '739',
      procedure: "Metal backing – per backing",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '741',
      procedure: "Adjustment of a denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '743',
      procedure: "Relining – complete denture – processed",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '744',
      procedure: "Relining – partial denture – processed",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '745',
      procedure: "Remodelling – complete denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '746',
      procedure: "Remodelling – partial denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '751',
      procedure: "Relining – complete denture – direct",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '752',
      procedure: "Relining – partial denture – direct",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '753',
      procedure: "Cleaning and polishing of pre-existing denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Maintenance',
      code: '754',
      procedure: "Denture base modification",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '761',
      procedure: "Reattaching pre-existing clasp to denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '762',
      procedure: "Replacing/adding clasp to denture – per clasp",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '763',
      procedure: "Repairing broken base of a complete denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '764',
      procedure: "Repairing broken base of a partial denture",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '765',
      procedure: "Replacing/adding new tooth on denture – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '766',
      procedure: "Reattaching existing tooth on denture – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '768',
      procedure: "Adding tooth to partial denture to replace an extracted or decoronated tooth – per tooth",
    },
    {
      category:'Prosthodontics',
      group:'Denture Repairs',
      code: '769',
      procedure: "Repair or addition to metal casting",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '771',
      procedure: "Tissue conditioning preparatory to impressions – per application",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '772',
      procedure: "Splint – resin – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '773',
      procedure: "Splint – metal – indirect",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '774',
      procedure: "Obturator",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '775',
      procedure: "Characterisation of denture base",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '776',
      procedure: "Impression – denture repair/modification",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '777',
      procedure: "Identification",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '778',
      procedure: "Inlay for denture tooth",
    },
    {
      category:'Prosthodontics',
      group:'Other Prosthodontic Services',
      code: '779',
      procedure: "Surgical guide for an immediate denture",
    },
    {
      category:'Orthodontics',
      group:'Removable Appliances',
      code: '811',
      procedure: "Passive removable appliance – per arch",
    },
    {
      category:'Orthodontics',
      group:'Removable Appliances',
      code: '821',
      procedure: "Active removable appliance – per arch",
    },
    {
      category:'Orthodontics',
      group:'Removable Appliances',
      code: '823',
      procedure: "Functional orthopaedic appliance – custom fabrication",
    },
    {
      category:'Orthodontics',
      group:'Removable Appliances',
      code: '824',
      procedure: "Functional orthopaedic appliance – prefabricated",
    },
    {
      category:'Orthodontics',
      group:'Removable Appliances',
      code: '825',
      procedure: "Sequential plastic aligners – per arch",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '829',
      procedure: "Partial banding – per arch",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '831',
      procedure: "Full arch banding – per arch",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '833',
      procedure: "Removal of banding – per arch",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '841',
      procedure: "Fixed palatal or lingual arch appliance",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '842',
      procedure: "Partial banding for inter-maxillary elastics (vertical and/or cross elastics)",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '843',
      procedure: "Expansion appliance – fixed – per arch",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '845',
      procedure: "Passive appliance – fixed",
    },
    {
      category:'Orthodontics',
      group:'Fixed Appliances',
      code: '846',
      procedure: "Minor tooth guidance – fixed",
    },
    {
      category:'Orthodontics',
      group:'Extraoral Appliances',
      code: '851',
      procedure: "Extraoral appliance",
    },
    {
      category:'Orthodontics',
      group:'Attachments',
      code: '862',
      procedure: "Bonding of attachment for application of orthodontic force",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '871',
      procedure: "Orthodontic adjustment",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '872',
      procedure: "Reattachment of passive appliance – fixed",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '873',
      procedure: "Repair of passive appliance – fixed",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '874',
      procedure: "Removal of passive appliance – fixed",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '875',
      procedure: "Repair of removable appliance – resin base",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '876',
      procedure: "Repair of removable appliance – clasp, spring or tooth",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '877',
      procedure: "Addition to removable appliance – clasp, spring or tooth",
    },
    {
      category:'Orthodontics',
      group:'Other Orthodontic Services',
      code: '878',
      procedure: "Relining – removable appliance – processed",
    },
    {
      category:'General Services',
      group:'Emergencies',
      code: '911',
      procedure: "Palliative care",
    },
    {
      category:'General Services',
      group:'Emergencies',
      code: '915',
      procedure: "After-hours callout",
    },
    {
      category:'General Services',
      group:'Emergencies',
      code: '916',
      procedure: "Travel to provide services",
    },
    {
      category:'General Services',
      group:'Drug Therapy',
      code: '926',
      procedure: "Individually made tray – medicament(s)",
    },
    {
      category:'General Services',
      group:'Drug Therapy',
      code: '927',
      procedure: "Provision of medication/medicament",
    },
    {
      category:'General Services',
      group:'Drug Therapy',
      code: '928',
      procedure: "Intravenous cannulation and establishment of infusion",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '941',
      procedure: "Local anaesthesia",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '942',
      procedure: "Sedation – intravenous – per 30 minutes or part thereof",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '943',
      procedure: "Sedation – inhalation – per 30 minutes or part thereof",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '944',
      procedure: "Relaxation therapy",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '945',
      procedure: "Low level laser therapy – per appointment",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '948',
      procedure: "Dental acupuncture – per appointment",
    },
    {
      category:'General Services',
      group:'Anaesthesia, Sedation and Relaxation Therapy',
      code: '949',
      procedure: "Treatment under general anaesthesia/sedation",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '961',
      procedure: "Minor occlusal adjustment – per appointment",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '963',
      procedure: "Clinical occlusal analysis, including muscle and joint palpation",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '964',
      procedure: "Registration and mounting of models for occlusal analysis",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '965',
      procedure: "Occlusal splint",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '966',
      procedure: "Adjustment of pre-existing occlusal splint – per appointment",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '967',
      procedure: "Pantographic tracing",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '968',
      procedure: "Occlusal adjustment following occlusal analysis – per appointment",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '971',
      procedure: "Adjunctive physical therapy for temporomandibular joint and associated structures – per appointment",
    },
    {
      category:'General Services',
      group:'Occlusal Therapy',
      code: '972',
      procedure: "Repair/addition – occlusal splint",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '981',
      procedure: "Splinting and stabilisation – direct – per tooth",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '982',
      procedure: "Enamel stripping – per appointment",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '983',
      procedure: "Single arch oral appliance for diagnosed snoring and obstructivesnoring and sleep apnoea",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '984',
      procedure: "Bi-maxillary oral appliance for diagnosed snoring and obstructive snoring and sleep apnoea",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '985',
      procedure: "Repair/addition – snoring or sleep apnoea device",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '986',
      procedure: "Post-operative care not otherwise included",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '987',
      procedure: "Recontour tissue – per appointment",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '990',
      procedure: "Treatment not otherwise included (specify)",
    },
    {
      category:'Miscellaneous',
      group:'Miscellaneous',
      code: '999',
      procedure: "GST",
    }
  ];
}

