import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedLocationService {

  constructor() { }

  locationChanged: EventEmitter<number> = new EventEmitter<number>();

  emitLocationChange(locationId: number) {
    this.locationChanged.emit(locationId);
  }

  getLocationChangeEmitter() {
    return this.locationChanged;
  }
}
